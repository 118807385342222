<template>
  <v-container>
    <confirm-dialog
      :confirm-click="deleteUser"
      :show="dialogDelete"
      @close="closeConfirmationDialog"
    >
      Are you sure you want to delete this user?
      <p>This user will no longer be able to access the platform.</p>
    </confirm-dialog>
    <v-row>
      <v-col
        cols="12"
        lg="8"
        md="10"
        xl="6"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-text-field
                ref="name"
                v-model="name"
                :rules="[rules.required]"
                clearable
                label="Full Name*"
                placeholder="Enter the full name"
                required
              />
              <v-text-field
                v-model="email"
                disabled
                label="Email Address*"
              />
              <label>Unit*</label>
              <v-autocomplete
                ref="unit"
                v-model="unit"
                :items="units"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                required
                single-line
              />
              <v-text-field
                ref="tagline"
                v-model="tagline"
                clearable
                label="Tagline"
                placeholder="Enter a tagline"
                required
              />
              <label>Projects/ Experience/ Motivation</label>
              <editor
                ref="about"
                :content="about"
                :show-submit-button="false"
                toolbar="linkToolbar"
                @keyUp="about = $event"
              />
              <template v-if="isExpert">
                <label>Interested in</label>
                <v-autocomplete
                  v-model="userTags"
                  :items="activeTags"
                  clearable
                  item-text="name"
                  item-value="id"
                  multiple
                  placeholder="Select"
                />
              </template>
              <br>
              <label>Contact Info*</label>
              <app-contact-info
                v-for="(contact, index) in contactInfo"
                :key="index"
                :contact="contact"
                :select-options="contactInfoValues"
                @input="updateContactInfo(index, $event)"
                @removeContact="removeContactInfo(index)"
              />

              <p
                class="add-link primary--text"
                @click="addContactInfo"
              >
                Add link
              </p>
              <login-mode
                v-if="isSsoCompany && loginMode"
                v-model="loginMode"
              />
              <v-checkbox
                v-model="deleteUserChecked"
                class="check-box"
                color="primary"
                input-value="true"
                label="Delete user"
              />
              <label>Once the user is deleted, it cannot be restore it</label>
              <div class="my-2">
                <app-button
                  :loading="loadingButton"
                  @click="manageSubmitButton"
                >
                  {{ deleteUserChecked ? 'Delete' : 'Save' }}
                </app-button>
              </div>
              <label class="body-1">* Mandatory field</label>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ConfirmDialog, ContactInfo, Editor, ValidateMixin } from '@kickbox/common-admin';
import { contactInfoValues, KICKBOX_ROLES } from '@kickbox/common-util';
import FEATURE_NAMES from '@kickbox/common-util/constants/feature-names';
import LOGIN_MODE from '@kickbox/common-util/constants/login-mode';
import userService from '@/services/userService';
import translationService from '@/services/translationService';
import LoginMode from './LoginMode';

export default {
  components: {
    appContactInfo: ContactInfo,
    ConfirmDialog,
    LoginMode,
    Editor
  },
  mixins: [ValidateMixin],
  props: ['id'],
  data() {
    return {
      fromExternal: false,
      contactInfoValues,
      name: '',
      email: '',
      unit: null,
      tagline: '',
      about: '',
      contactInfo: '',
      loadingButton: false,
      deleteUserChecked: false,
      dialogDelete: false,
      userTags: [],
      loginMode: null
    };
  },
  computed: {
    ...mapGetters([
      'userById',
      'company',
      'users',
      'units',
      'activeTags'
    ]),
    user() {
      return this.userById(this.id);
    },
    formMandatoryFields() {
      return {
        name: this.name,
        unit: this.unit
      };
    },
    isExpert() {
      return this.user && this.user.role.includes(KICKBOX_ROLES.EXPERT);
    },
    isSsoCompany() {
      return this.company.features[FEATURE_NAMES.SSO];
    }
  },
  async created() {
    if (!this.user) {
      await userService.getUsersPerCompany();
    }
    if (this.user) {
      this.name = this.user.name;
      this.email = this.user.email;
      this.unit = this.user.unit?.id;
      this.tagline = this.user.tagline;
      this.about = this.user.about;
      this.userTags = this.user.tags.map((t) => t.id);
      this.contactInfo = this.user.contactInfo;
      this.loginMode = this.user.loginMode || LOGIN_MODE.SSO;
    } else {
      this.$router.push({ name: 'AdminHome' });
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromExternal = from.fullPath === '/';
    });
  },
  methods: {
    ...mapMutations([
      'updateUser',
      'removeUser'
    ]),
    closeConfirmationDialog() {
      this.dialogDelete = false;
    },
    deleteUser() {
      this.closeConfirmationDialog();
      userService.deleteUser(this.user.id);
      this.removeUser(this.user);
      if (this.fromExternal) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'AdminManageUsers' });
      }
    },
    manageSubmitButton() {
      if (this.deleteUserChecked) {
        this.dialogDelete = true;
      } else {
        this.saveProfile();
      }
    },
    addContactInfo() {
      this.contactInfo.push({ type: '', value: '' });
    },
    updateContactInfo(updateIndex, newContactInfo) {
      this.contactInfo = this.contactInfo
        .map((contact, index) => (
          index === updateIndex ? newContactInfo : contact
        ));
    },
    removeContactInfo(removeIndex) {
      this.contactInfo = this.contactInfo
        .filter((item, index) => removeIndex !== index);
    },
    formatUserPropertiesValues(parseObject) {
      return {
        id: parseObject.id,
        name: parseObject.get('name'),
        unit: parseObject.get('unit') ? {
          id: parseObject.get('unit').id,
          name: parseObject.get('unit').get('name')
        } : null,
        tagline: parseObject.get('tagline'),
        photo: parseObject.get('photo'),
        about: parseObject.get('about'),
        contactInfo: parseObject.get('contactInfo'),
        tags: parseObject.get('tags') ? parseObject.get('tags')
          .map((t) => ({ id: t.id, name: t.get('name') })) : [],
        loginMode: parseObject.get('loginMode')
      };
    },
    async saveProfile() {
      const verifiedContactInfo = this.checkContactInfo(this.contactInfo);
      if (this.checkFields() && verifiedContactInfo) {
        this.loadingButton = true;

        const entityProperties = {
          id: this.id,
          userProperties: {
            name: this.name,
            tagline: this.tagline,
            about: this.about,
            contactInfo: verifiedContactInfo,
            tags: this.userTags,
            unitId: this.unit
          }
        };

        if (this.isSsoCompany) {
          entityProperties.userProperties.loginMode = this.loginMode;
        }

        const user = await userService.updateUser(entityProperties);
        translationService.clearTranslation(`User-${this.id}`);
        this.updateUser(this.formatUserPropertiesValues(user));
        if (!await userService.canIModifyUser(this.id)) {
          this.removeUser(this.user);
        }
        if (this.fromExternal) {
          this.$router.push({ name: 'AdminManageUsers' });
        } else {
          this.$router.go(-1);
        }
      }
    }
  }
};
</script>

<style scoped>
label {
  color: rgba(0, 0, 0, .54);
  font-size: 12px;
}

.dialog p {
  font-size: initial;
  font-weight: normal;
  margin-top: 20px;
}

.add-link {
  font-size: 12px;
  cursor: pointer;
}

.croppa-container {
  border: 1px solid grey;
}
</style>
