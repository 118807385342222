<template>
  <v-radio-group
    v-model="loginMode"
    @change="$emit('input', loginMode);"
  >
    <v-radio
      label="Login with sso"
      :value="sso"
    />
    <v-radio
      label="Login with password"
      :value="password"
    />
  </v-radio-group>
</template>

<script>
import { SSO, PASSWORD } from '@kickbox/common-util/constants/login-mode';

export default {
  name: 'LoginMode',
  props: {
    value: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      loginMode: this.value || SSO,
      sso: SSO,
      password: PASSWORD
    };
  }
};
</script>
